import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyANPVJEOIz2vll2lYc-4vhcqsqzq6JlxRQ",
  authDomain: "smerch-39c4d.firebaseapp.com",
  databaseURL: "https://smerch-39c4d-default-rtdb.firebaseio.com",
  projectId: "smerch-39c4d",
  storageBucket: "smerch-39c4d.appspot.com",
  messagingSenderId: "701593427856",
  appId: "1:701593427856:web:ca0386e9ed7b2ec51b9d15",
  measurementId: "G-E4QRYSNV9X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// const analytics = getAnalytics(app);
export { 
  db, 
  auth, 
  storage, 
  functions,
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut 
};